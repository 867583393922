import * as React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import { Nav } from "react-bootstrap"
import { faFacebookF, faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons"
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Img from "gatsby-image"
import * as footerStyles from "./footer.module.scss"

const Footer = () => {
  const data = useStaticQuery(graphql`
      query {
          file(relativePath: {eq: "SkillCourts-logo.png"}) {
              childImageSharp {
                  fixed(width: 250, quality: 100) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }
      }
  `)
  return (
    <footer className="pt-5 pb-5">
      <div className='container-fluid'>
        <div className={`row ${footerStyles.footerNav}`}>
          <div className="col-md-12 col-lg-4 d-flex justify-content-center align-items-center">
            <Link to="/">
              <Img
                className="gatsby-image"
                imgStyle={{ objectFit: 'contain' }}
                fixed={ data.file.childImageSharp.fixed }
              />
            </Link>
          </div>
          <div className="col-sm-12 col-md-3 col-lg-2">
            <Nav activeKey="/home" className={footerStyles.navBlock}>
              <h4>Urban SkillCourts</h4>
              <Nav.Item>
                <Nav.Link href="https://smartgoals.nl/"
                          target="_blank" rel="noopener noreferrer">
                  About Us
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link href="https://smartgoals.nl/contact/"
                          target="_blank" rel="noopener noreferrer">
                  Contact
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
          <div className="col-sm-12 col-md-4 col-lg-2">
            <Nav activeKey="/home" className={footerStyles.navBlock}>
              <h4>Help</h4>
              <Nav.Item>
                <Nav.Link
                  href='https://www.smartgoals.nl/downloads/General_Terms_and_Conditions_of_Sales.pdf?x27335'
                  target="_blank" rel="noopener noreferrer">
                  General Terms
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  href='https://www.smartgoals.nl/downloads/PrivacyDeclaration%20SmartGoals%20B.V..pdf?x27335'
                  target="_blank" rel="noopener noreferrer">
                  Privacy Policy
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
          <div className="col-sm-12 col-md-5 col-lg-4">
            <Nav className={`${footerStyles.navBlock} ${footerStyles.contactUs}`}>
              <h4>Contact Us</h4>
              <div className="d-flex flex-row align-items-center">
                <FontAwesomeIcon className={footerStyles.envelopIcon} icon={ faEnvelope } size="2x" />
                info@smartgoals.nl
              </div>
              <div className="d-flex flex-row align-items-center">
                <FontAwesomeIcon className={footerStyles.envelopIcon} icon={ faPhone } size="2x" />
                +31 252 823 071
              </div>
              <div className='d-flex flex-row align-items-center'>
                <a href="https://www.facebook.com/smartgoalssport/"
                   target="_blank" rel="noopener noreferrer"
                   className={`mr-3 ${footerStyles.socialNetworkLogo}`}>
                  <FontAwesomeIcon icon={ faFacebookF } size="1x" />
                </a>
                <a href="https://www.instagram.com/smartgoalsfootball/"
                   target="_blank" rel="noopener noreferrer"
                   className={`mr-3 ${footerStyles.socialNetworkLogo}`}>
                  <FontAwesomeIcon className={footerStyles.socialNetworkLogo} icon={ faInstagram } size="1x" />
                </a>
                <a href="https://twitter.com/smartgoalssport?lang=en"
                   target="_blank" rel="noopener noreferrer"
                   className={footerStyles.socialNetworkLogo}>
                  <FontAwesomeIcon icon={ faTwitter } size="1x" />
                </a>
              </div>
            </Nav>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
