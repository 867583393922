import { graphql, Link, useStaticQuery  } from "gatsby";
import React, { useState } from "react";
import { animateScroll as scroll } from "react-scroll";
import { Nav, Navbar, Dropdown } from "react-bootstrap";
import Image from "gatsby-image";
import './header.scss';
import { usePageContext } from "@3nvi/gatsby-theme-intl"

const Header = ({children}) => {
  const { lang, supportedLanguages, originalPath } = usePageContext()
  const data = useStaticQuery(getHeaderAssets)

  const scrollToTop = (e) => {
    e.preventDefault();
    scroll.scrollToTop();
  };

  const languages = [
    {
      lang: 'en',
      value: 'English',
      img: 'flagEnglish'
    },
    {
      lang: 'nl',
      value: 'Dutch',
      img: 'flagDutch'
    }
  ];
  const idx = languages.findIndex(x => x.lang === lang);
  console.log(supportedLanguages)
  const [active, setActive] = useState(languages[idx])

  return (
    <header className={`pd-lg-1 nav-header`}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <Navbar expand="lg">
              <Navbar.Brand as={Link} to='/' style={{width: '12rem'}} onClick={e => scrollToTop(e)}>
                <Image style={{width: "200px"}} fluid={data.skillcourtsNavLogo.childImageSharp.fluid} alt=""/>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav"/>
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className={`ml-auto`}>
                  <Nav.Link className="pr-lg-4" as={Link} to='/' onClick={e => scrollToTop(e)}>Home</Nav.Link>
                  <Nav.Link className="pr-lg-4"
                            href="https://smartgoals.nl/"
                            target="_blank" rel="noopener noreferrer">
                    About
                  </Nav.Link>
                  <Nav.Link href="https://smartgoals.nl/contact/"
                            target="_blank" rel="noopener noreferrer">
                    Contact
                  </Nav.Link>
                  {children}
                  <Dropdown className="d-flex position-relative language-switcher-btn">
                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                      <Image className="lang-flag-img"
                             fluid={data[active['img']].childImageSharp.fluid}
                             alt=""/>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {
                        languages.map((item, i) => {
                          return (
                            <Dropdown.Item as={ Link }
                                           className="d-flex flex-row align-items-center"
                                           href={`/${item.lang}${originalPath}`} onClick={() => {
                              setActive(item);
                            }}>
                              <Image className="lang-flag-img mr-2"
                                     fluid={data[item['img']].childImageSharp.fluid}
                                     alt="Country flag"/>
                              {item.value}
                            </Dropdown.Item>
                          )
                        })
                      }
                    </Dropdown.Menu>
                  </Dropdown>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header

export const getHeaderAssets = graphql`
    query {
        skillcourtsNavLogo: file(relativePath: { eq: "SkillCourts-logo.png" }) {
            childImageSharp {
                fluid(maxHeight: 400) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        flagEnglish: file(relativePath: { eq: "flag-english.png" }) {
            childImageSharp {
                fluid(maxHeight: 200) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        flagDutch: file(relativePath: { eq: "flag-dutch.png" }) {
            childImageSharp {
                fluid(maxHeight: 200) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`

