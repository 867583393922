/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { Link, Element } from "react-scroll"
import { Button } from "react-bootstrap"

import Header from "./header"
import WelcomeSection from "./sections/welcome"
import WhySection from "./sections/why"
import HowSection from "./sections/how"
import GetOfferSection from "./sections/get_offer"
import WhatSection from "./sections/what";
import Footer from "./footer"

import "./layout.css"
import "./layout.scss"
import SEO from "./seo";


const Layout = () => {

  return (
    <>
      <SEO/>
      <Header>
        <Link activeClass="active" to="section-5" spy={true} smooth={true} duration={500}
              className="topnavbar-link justify-content-center pr-lg-2">
          <Button
            to="section-5" spy={true} smooth={true} duration={500}
            className="offer-btn-top">Get an offer</Button>
        </Link>
      </Header>
      <main>
        <div className="container-fluid sections-main">
          <Element name="section-1" className="section">
            <WelcomeSection/>
          </Element>
          <Element name="section-2" className="section">
            <WhySection/>
          </Element>
          <Element name="section-3" className="section">
            <HowSection/>
          </Element>
          <Element name="section-4" className="section">
            <WhatSection/>
          </Element>
          <Element name="section-5" className="section">
            <GetOfferSection/>
          </Element>
          <Footer/>
        </div>
        <div className={"sidenavigation"}>
          <ul className="events">
            <li>
              <Link activeClass="active" to="section-1" spy={true} smooth={true} duration={500}>HOME</Link>
            </li>
            <li>
              <Link activeClass="active" to="section-2" spy={true} smooth={true} duration={500}>WHY</Link>
            </li>
            <li>
              <Link activeClass="active" to="section-3" spy={true} smooth={true} duration={500}>HOW</Link>
            </li>
            <li>
              <Link activeClass="active" to="section-4" spy={true} smooth={true} duration={500}>WHAT</Link>
            </li>
            <li>
              <Link activeClass="active" to="section-5" spy={true} smooth={true} duration={500}>GET AN OFFER</Link>
            </li>
          </ul>
        </div>
      </main>
    </>
  )
}

export default Layout;
